import { QueryPage, QueryPageResult } from './index'
import { alphaUrl, ajaxApi } from '@/utils/request'
import { tardeMap, getOptions } from '@/utils/filters'

/** 合同信息 */
export type ContractInfo = {
  /** 合同ID */
  contractId: string
  /** T豆数量 */
  freeTdou: number
  /** 约定到期时间 */
  preDueDate: string
}

/** 交易信息 */
export type TradeInfo = {
  /** 交易流水号 */
  flowId: string
  /** 批次号 */
  batchId: string
  /** 操作者Id */
  operatorId: string
  /** 操作者名称 */
  operatorName: string
  /** T豆数量 */
  freeTdou: number
  /** 备注 */
  remark: string
  /** 合同ID */
  tdouAmt: number
  /** 交易时间 */
  tradeTime: string
  /** 交易类型 */
  tradeType: '' | '00' | '01' | '02'
}

export type TradeQueryPage = QueryPage &
  Partial<
    TradeInfo & {
      /** 交易开始日期 */
      tradeStartDate: string
      /** 交易结束日期 */
      tradeEndDate: string
      /** 交易日期 */
      tradeDate: [string, string]
    }
  >

/** 查询当前有效的合同 */
export const getActiveContract = () => ajaxApi('get', alphaUrl + '/contr/query/active') as Promise<ContractInfo>

/** 新增合同 */
export const addContract = (params: ContractInfo) => ajaxApi('post', alphaUrl + '/contr/add', params)

/** 分页查询交易 */
export const getTradePage = (params: TradeQueryPage) =>
  ajaxApi('get', alphaUrl + '/contr/flow/query/page', params) as Promise<QueryPageResult<TradeInfo>>

/** 获取交易类型 */
export const getTradeTypeList = () => Promise.resolve(getOptions(tardeMap))
